import React from 'react';
import '../App.scss';

const Technologies = () => (
  <div className="section-odd">
    <div className="container">
      <h3>Technologies:</h3>
      <div className="row text-center">
        <div className="col-md-6">
          <p>
            <i className="icon-sitemap icon-4x" />
            {' '}
          </p>
          <p>
                Full Stack
            <br />
                Ruby on Rails
            <br />
                React.js
            <br />
          </p>
        </div>
        <div className="col-md-6">
          <p>
            <i className="icon-cloud icon-4x" />
            {' '}
          </p>
          <p>
                Web / Cloud
            <br />
                Heroku / AWS
            <br />
                Postgresql / No sql
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Technologies;
