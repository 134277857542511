import React from 'react';
import '../App.scss';

const Solutions = () => (
  <div className="section-even">
    <div className="container">

      <h3>We can help with:</h3>
      <ul>
        <li>
          Support and upgrade your existing custom software,
          e.g. Excel and Access applications.
        </li>
        <li>
          Move from paper and spreadsheets to efficient digital systems.
        </li>
        <li>
          Move from in-house servers to cloud based systems.
        </li>
        <li>
          Integrate mobile technologies with existing software systems.
        </li>
      </ul>

      <h3>Application examples:</h3>
      <ul>
        <li>
            Order System. Electronic forms replace paper forms with workflows
            to process order through to delivery.
        </li>
        <li>
          Lead Tracking. Replace phone message pads with online application
          to direct leads to sales dashboard allows reporting and no missed
          follow ups.
        </li>
        <li>
          Add tablet access to internal systems. Allow data entry in the field
          or at a trade show and uploading to internal systems.
        </li>
      </ul>
    </div>
  </div>
);

export default Solutions;
