import React from 'react';
import '../App.scss';

const Contact = () => (
  <div className="section-even">
    <div className="container">
      <h2>Contact</h2>
      <p>Principle: Nik Swain</p>
      <p className="ml-3">
        Cornerhouse Software
        <br />
        8421 Quayside Court,
        <br />
        Vancouver, V5P 4W1,
        <br />
        Canada
        <br />
      </p>
      <div>
        <i className="icon-phone" />
        {' '}
        <a href="callto:(778) 855-6102">(778) 855-6102</a>
      </div>
      <i className="icon-envelope-alt" />
      {' '}
      <a href="email:info1@cornerhouse.ca">info1@cornerhouse.ca</a>
    </div>
  </div>
);

export default Contact;
