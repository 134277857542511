import React from 'react';
import '../App.scss';

const Features = () => (
  <div className="section-odd">
    <div className="container text-center">
      <div className="row">
        <div className="col-md-4">
          <h3>
            <i className="icon-dashboard icon-4x" />
            <br />
            {' '}
            On Demand
          </h3>
          <p>
            No hiring or re-tasking of employees. A single
            developer or whole team for the duration of the project.
          </p>
        </div>
        <div className="col-md-4">
          <h3>
            <i className="icon-group icon-4x" />
            <br />
            {' '}
            Local
          </h3>
          <p>
            Based in the Greater Vancouver area. All work local, no offshore.
            Face to face meetings to get the requirements right.
          </p>
        </div>
        <div className="col-md-4">
          <h3>
            <i className="icon-thumbs-up-alt icon-4x" />
            <br />
            {' '}
            Quality and Satisfaction
          </h3>
          <p>
            We want to work with the same customers over and over.
            Once we know your systems it is easier to help again.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Features;
