import React from 'react';
import '../App.scss';

const Hero = () => (
  <div className="container-fluid hero text-center">
    <h1>Cornerhouse Software</h1>
    <h4>Solution Architecture and Software Development</h4>
    <p>
      Cornerhouse designs solutions that fit with your business.
      {' '}
      <br />
      Solutions designed to work with existing systems and processes.
      {' '}
      <br />
      Software developed in-house or by bringing in experts in appropriate fields.
      {' '}
      <br />
    </p>
  </div>
);

export default Hero;
