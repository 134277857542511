import React from 'react';

import Hero from './hero';
import Features from './features';
import Solutions from './solutions';
import Technologies from './technologies';
import Contact from './contact';

import '../App.scss';

const Layout = () => (
  <div>
    <Hero />
    <Features />
    <Solutions />
    <Technologies />
    <Contact />
    <div className="container">
      <p style={{ fontSize: 10 }}>
        All content copyright Cornerhouse Software.
      </p>
    </div>
  </div>
);

export default Layout;
